import { z } from 'zod';

import { OwnerAddress } from '../../../../types/putOwner';

export const validateAddress = (address: OwnerAddress) => {
  const addressSchema = z.object({
    addressLine1: z.string().min(2),
    city: z.string(),
    postalCode: z.string(),
    administrativeAreaLevel1: z.string(),
    country: z.string(),
  });

  return addressSchema.safeParse(address);
};
