import * as React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { DayPicker } from 'react-day-picker';
import clsx from 'clsx';
export type CalendarProps = React.ComponentProps<typeof DayPicker>;
export const Calendar = ({
  className,
  classNames,
  disabled = {
    before: new Date()
  },
  showOutsideDays = true,
  ...props
}: CalendarProps) => <DayPicker showOutsideDays={showOutsideDays} disabled={disabled} className={clsx('py-3 px-2', className)} classNames={{
  months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 font-muli',
  month: 'space-y-4',
  caption: 'flex justify-center pt-1 relative items-center font-muli',
  caption_label: 'text-sm font-medium',
  nav: 'space-x-1 flex items-center font-muli',
  nav_button: clsx('h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'),
  nav_button_previous: 'absolute left-1 border-none disabled:opacity-50 disabled:pointer-events-none',
  nav_button_next: 'absolute right-1 border-none',
  table: 'w-full border-collapse space-y-1 border border-solid border-transparent border-t-backgroundGray',
  head_row: 'flex',
  head_cell: 'text-charcoal rounded-full w-9 font-normal text-[0.8rem] mt-3 text-[10px] uppercase',
  row: 'flex w-full mt-[10px]',
  button: 'bg-transparent rounded-full text-[12px]',
  cell: clsx('text-center text-sm p-0 relative first:[&:has([aria-selected])]:rounded-l-full', 'last:[&:has([aria-selected])]:rounded-r-full focus-within:relative focus-within:z-20 mx-[1px]'),
  day: clsx('text-charcoal hover:bg-caribbeanGreen/20 hover:text-caribbeanGreen', 'h-9 w-9 p-0 font-normal aria-selected:opacity-100 border-none font-muli cursor-pointer disabled:pointer-events-none'),
  day_selected: '!bg-caribbeanGreen !text-white hover:text-white focus:bg-caribbeanGreen focus:text-white',
  day_today: 'font-muli-bold',
  day_outside: '!opacity-0 pointer-events-none',
  day_disabled: 'text-slate-500 opacity-50',
  day_range_middle: 'aria-selected:text-white !bg-caribbeanGreen/60',
  day_hidden: 'invisible',
  ...classNames
}} components={{
  IconLeft: () => <span><ChevronLeftIcon className="h-4 w-4 cursor-pointer text-charcoal" /></span>,
  IconRight: () => <span><ChevronRightIcon className="h-4 w-4 cursor-pointer text-charcoal" /></span>
}} {...props} data-sentry-element="DayPicker" data-sentry-component="Calendar" data-sentry-source-file="calendar.tsx" />;
Calendar.displayName = 'Calendar';