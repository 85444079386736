'use client';

import clsx from 'clsx';
import { add, sub, parseISO, isWithinInterval } from 'date-fns';
import React, { useEffect } from 'react';
import { formatDate } from '../../../../../VisitServiceContainer/helpers';
import { useBookingStore, useFiltersStore } from '../../../../context';
import { getCaption } from '../../../../helpers/getCaption';
import { filterFutureDates, isDateInPast } from '../../../../helpers/dateValidation';
import { closePickerWithDelay, getMultipleDateConstraints, formatAndSortDates } from '../../../../helpers/datePickerUtils';
import { BookingDatePicker } from './BookingDatePicker';
import { FilterTitle } from './FilterTitle';
import { useGetBookingType } from '../../../../helpers/useGetBookingType';
type Props = {
  isModal?: boolean;
  fullWidthAll?: boolean;
  iconColor?: string;
  triggerButtonClass?: string;
  dateCaptionClassName?: string;
  titleClassName?: string;
  disabledDays?: Date[];
  title?: string;
  onMonthChange?: (date: Date) => void;
  showArrow?: boolean;
  containerClass?: string;
};
export const DateFilters = ({
  isModal,
  fullWidthAll,
  iconColor,
  triggerButtonClass,
  dateCaptionClassName,
  titleClassName,
  disabledDays = [],
  title = 'Dates',
  onMonthChange,
  showArrow,
  containerClass,
  ...rest
}: Props) => {
  const {
    startDate,
    endDate
  } = useFiltersStore(state => state.filters);
  const setFilters = useFiltersStore(state => state.setFilters);
  const setSchedule = useBookingStore(state => state.setSchedule);
  const {
    selectedDays
  } = useBookingStore(state => state.schedule);
  const filteredSelectedDays = filterFutureDates(selectedDays);

  // Clean up past dates from stores
  useEffect(() => {
    const hasRemovedDates = selectedDays.length !== filteredSelectedDays.length;
    if (hasRemovedDates) {
      setSchedule({
        selectedDays: filteredSelectedDays
      });
    }
  }, [selectedDays, filteredSelectedDays, setSchedule]);
  useEffect(() => {
    const shouldResetDates = startDate && isDateInPast(startDate);
    if (shouldResetDates) {
      setFilters({
        startDate: null,
        endDate: null
      });
    }
  }, [startDate, setFilters]);
  const castDisabledDays = disabledDays.map(day => new Date(day));
  const {
    isWalkOrDropIn
  } = useGetBookingType();
  const isAnyDateDisabled = (dateRange, disabledDates) => {
    const {
      from,
      to
    } = dateRange;
    if (!to || !from) {
      return;
    }

    // Convert string dates in disabledDates to Date objects
    const disabledDateObjects = disabledDates.map(date => new Date(date));
    // Check if any date in the range is a disabled date
    return disabledDateObjects.some(disabledDate => isWithinInterval(disabledDate, {
      start: new Date(from),
      end: new Date(to)
    }));
  };
  return <div className={clsx('flex justify-between sm:ml-auto md:ml-0', containerClass)} data-sentry-component="DateFilters" data-sentry-source-file="DateFilters.tsx">
      {isWalkOrDropIn ? <div className={clsx(fullWidthAll ? 'w-full' : 'w-full md:w-[150px] lg:w-[200px]')}>
          <FilterTitle title={title} className={titleClassName} />
          <BookingDatePicker showArrow={showArrow} iconColor={iconColor} calendarProps={{
        mode: 'multiple',
        selected: filteredSelectedDays.length ? filteredSelectedDays.map(selectedDate => parseISO(selectedDate)) : [],
        disabled: getMultipleDateConstraints(filteredSelectedDays),
        onSelect: dates => {
          setSchedule({
            selectedDays: formatAndSortDates(dates)
          });
        }
      }} clearDates={() => {
        setSchedule({
          selectedDays: []
        });
      }} showSaveButton align={isModal || fullWidthAll ? 'center' : 'end'} sideOffset={isModal ? -150 : 0} caption={getCaption({
        type: 'multiple',
        selectedDays: filteredSelectedDays
      })} dateCaptionClassName={dateCaptionClassName} triggerButtonClass={triggerButtonClass} {...rest} />

        </div> : <div className={clsx(fullWidthAll ? 'w-full' : 'w-full md:w-[130px] lg:w-[200px]')}>
          <FilterTitle title={title} className={titleClassName} />
          <BookingDatePicker showArrow={showArrow} iconColor={iconColor} calendarProps={{
        mode: 'range',
        selected: {
          from: startDate && parseISO(startDate),
          to: endDate && parseISO(endDate)
        },
        onMonthChange,
        disabled: [{
          before: new Date()
        }, ...castDisabledDays],
        onSelect: dates => {
          if (isAnyDateDisabled(dates, castDisabledDays)) {
            return;
          }
          setFilters({
            startDate: formatDate(dates?.from),
            endDate: formatDate(dates?.to)
          });
          if (dates?.from && dates?.to) {
            closePickerWithDelay();
          }
        }
      }} align={isModal || fullWidthAll ? 'center' : 'end'} sideOffset={isModal ? -150 : 0} caption={getCaption({
        type: 'range',
        startDate,
        endDate
      })} dateCaptionClassName={dateCaptionClassName} triggerButtonClass={triggerButtonClass} {...rest} />
        </div>}
    </div>;
};