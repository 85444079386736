import { Mapbox } from '../../../constants/app';
import { ENV_MAPBOX_API_KEY } from '../../../constants/envs';
import { tryCatch } from '../../../utils/tryCatch';
import { httpGet } from '../../../wag-react/common/api/api';
import { GeocodingResponse } from '../getGeocoding';

type ReverseGeocodingProps = {
  coordinates: [number, number];
}

export const getReverseGeocoding = async ({
  coordinates,
}: ReverseGeocodingProps) => {
  if (!coordinates) return [];
  const geoReverseCodingUrl = Mapbox.Geocoding.replace(
    '{{location}}',
    `${coordinates?.[0]?.toString()},${coordinates?.[1]?.toString()}`,
  );

  const [response, err] = await tryCatch(
    () => httpGet<GeocodingResponse>(geoReverseCodingUrl, {
      params: {
        access_token: ENV_MAPBOX_API_KEY,
        autocomplete: true,
        country: 'US',
        proximity: 'ip',
      },
    }));

  if (err) {
    throw err;
  }

  let location = {
    context: [],
  };

  for (let i = 0; i < response.data.features.length; i += 1) {
    const feature = response.data.features[i];
    if (feature.id.includes('place')) {
      location = feature;
    }
    location.context.push(feature);
  }

  return [location] as GeocodingResponse['features'];
};
