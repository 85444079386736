/* eslint-disable max-len */
export const ENV_NEWRELIC_BROWSER_ACCOUNT_ID = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_ACCOUNT_ID;
export const ENV_NEWRELIC_BROWSER_AGENT_ID = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_AGENT_ID;
export const ENV_NEWRELIC_BROWSER_APPLICATION_ID = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_APPLICATION_ID;
export const ENV_NEWRELIC_BROWSER_TRUST_KEY = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_TRUST_KEY;
export const ENV_NEWRELIC_BROWSER_LICENSE_KEY = process.env.NEXT_PUBLIC_NEWRELIC_BROWSER_LICENSE_KEY;
export const ENV_MAPBOX_API_KEY = process.env.NEXT_PUBLIC_MAPBOX_API_KEY;

export const APPLE_ID = process.env.NEXT_PUBLIC_APPLE_APP_ID;
export const APPLE_ID_REDIRECT_URI = process.env.NEXT_PUBLIC_APPLE_ID_REDIRECT_URI;
