import React from 'react';
import { cn } from '../utils/tailwind';
export type ScheduleIconProps = {
  className?: string;
  color?: string;
};
export const ScheduleIcon = ({
  className,
  color = 'caribbbeanGreen',
  ...rest
}: ScheduleIconProps) => <div className={cn('flex justify-center align-center mr-2', className)} {...rest} data-sentry-component="ScheduleIcon" data-sentry-source-file="ScheduleIcon.tsx">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 30 30" fill="none" data-sentry-element="svg" data-sentry-source-file="ScheduleIcon.tsx">
      <path fill={color} d="M21.25 17.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5m0 5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5m-5-6.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0m0 5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0m-7.5-3.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5m0 5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5" data-sentry-element="path" data-sentry-source-file="ScheduleIcon.tsx" />
      <path fill={color} fillRule="evenodd" d="M8.75 2.188a.937.937 0 0 1 .938.937v.954c.827-.016 1.738-.016 2.74-.016h5.142c1.004 0 1.915 0 2.742.016v-.954a.938.938 0 0 1 1.875 0v1.034c.325.025.633.056.924.095 1.465.197 2.651.612 3.588 1.547.935.936 1.35 2.123 1.547 3.588.192 1.425.192 3.244.192 5.541v2.64c0 2.297 0 4.117-.192 5.541-.197 1.465-.612 2.651-1.547 3.588-.937.935-2.123 1.35-3.588 1.547-1.425.192-3.244.192-5.541.192h-5.14c-2.297 0-4.117 0-5.541-.192-1.465-.197-2.652-.612-3.588-1.547-.935-.937-1.35-2.123-1.547-3.588-.192-1.425-.192-3.244-.192-5.541v-2.64c0-2.297 0-4.117.192-5.541C1.95 7.924 2.366 6.738 3.3 5.8c.936-.935 2.123-1.35 3.588-1.547.291-.039.6-.07.923-.095V3.125a.937.937 0 0 1 .938-.938ZM7.138 6.111c-1.257.17-1.982.487-2.51 1.016-.53.528-.847 1.253-1.016 2.51a14.62 14.62 0 0 0-.072.675h22.92c-.02-.238-.044-.462-.073-.676-.168-1.256-.486-1.98-1.015-2.51-.528-.528-1.253-.846-2.51-1.014-1.284-.173-2.978-.176-5.362-.176h-5c-2.384 0-4.076.003-5.362.175ZM3.438 15c0-1.068 0-1.996.016-2.813h23.092c.017.817.017 1.745.017 2.813v2.5c0 2.384-.003 4.078-.175 5.363-.17 1.256-.487 1.98-1.016 2.51-.528.528-1.253.846-2.51 1.014-1.284.173-2.978.175-5.362.175h-5c-2.384 0-4.076-.002-5.362-.175-1.257-.168-1.982-.486-2.51-1.015-.53-.528-.847-1.253-1.016-2.51-.172-1.284-.175-2.978-.175-5.362V15Z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="ScheduleIcon.tsx" />
    </svg>
  </div>;