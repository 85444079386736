import { add, sub, parseISO } from 'date-fns';
import { formatDate } from '../../VisitServiceContainer/helpers';

/**
 * Creates an escape key press event to close date pickers
 */
export const createEscapeKeyEvent = (): KeyboardEvent => {
  return new KeyboardEvent('keydown', {
    key: 'Escape',
    keyCode: 27,
    code: 'Escape',
    which: 27,
    bubbles: true,
    cancelable: true,
  });
};

/**
 * Dispatches an escape key event after a short delay
 */
export const closePickerWithDelay = (delay = 200): void => {
  setTimeout(() => {
    document.dispatchEvent(createEscapeKeyEvent());
  }, delay);
};

/**
 * Generates date range constraints for multiple date selection
 * Prevents selecting dates within 6 days of already selected dates
 */
export const getMultipleDateConstraints = (selectedDays: string[]) => {
  if (!selectedDays || !selectedDays.length) return [{ before: new Date() }];
  
  return [
    { before: new Date() },
    ...selectedDays.map((selectedDay) => ({
      before: sub(parseISO(selectedDay), { days: 6 }),
      after: add(parseISO(selectedDay), { days: 6 }),
    }))
  ];
};

/**
 * Formats and sorts date objects for storage
 */
export const formatAndSortDates = (dates: Date[]): string[] => {
  return dates
    .sort((a, b) => a.getTime() - b.getTime())
    .map(date => formatDate(date));
};
