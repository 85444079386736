import {
  format, parseISO,
} from 'date-fns';

type Props = {
  type: 'single' | 'multiple' | 'range';
  selectedDays?: string[];
  startDate?: string;
  endDate?: string;
}

export const getCaption = ({
  type, selectedDays, startDate, endDate,
}: Props) => {
  if (type === 'single') {
    if (!startDate) return `Select your date`;

    return format(parseISO(startDate), 'MM/dd/yyyy');
  }

  if (type === 'multiple') {
    return selectedDays?.map(
      (selectedDate) => format(parseISO(selectedDate), 'LLL dd')).join(', ') || `Select your dates`;
  }

  if (type === 'range') {
    if (!startDate && !endDate) {
      return `Select your dates`;
    }

    return `
      ${startDate ? format(parseISO(startDate), 'MM/dd/yyyy') : 'Start date'} -
      ${endDate ? format(parseISO(endDate), 'MM/dd/yyyy') : 'End date'}
    `;
  }

  return format(parseISO(startDate), 'MM/dd/yyyy');
};

const formatDate = (date: string) => format(parseISO(date), 'LLL d');

const formatSingleDate = (startDate?: string) => 
  startDate ? formatDate(startDate) : 'Pick a date';

const formatMultipleDates = (selectedDays: string[]) => {
  if (selectedDays.length === 0) return 'Pick dates';

  const groupedByMonth = selectedDays.reduce((acc: { [key: string]: string[] }, date) => {
    const [month, day] = formatDate(date).split(' ');
    if (!acc[month]) acc[month] = [];
    acc[month].push(day);
    return acc;
  }, {});

  return Object.entries(groupedByMonth)
    .map(([month, days]) => `${month} ${days.join(', ')}`)
    .join(', ');
};

const formatDateRange = (startDate?: string, endDate?: string) => {
  if (!startDate && !endDate) return 'Pick dates';
  if (startDate && endDate) {
    const [startMonth, startDay] = formatDate(startDate).split(' ');
    const [endMonth, endDay] = formatDate(endDate).split(' ');
    return startMonth === endMonth
      ? `${startMonth} ${startDay}-${endDay}`
      : `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }
  return `${startDate ? formatDate(startDate) : 'Start'} - ${endDate ? formatDate(endDate) : 'End'}`;
};

export const getShortDateCaption = ({ type, selectedDays = [], startDate, endDate }: Props) => {
  switch (type) {
  case 'single':
    return formatSingleDate(startDate);
  case 'multiple':
    return formatMultipleDates(selectedDays);
  case 'range':
    return formatDateRange(startDate, endDate);
  default:
    return startDate ? formatDate(startDate) : '';
  }
};
