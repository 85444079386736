'use client';

import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { useVirtualizer } from '@tanstack/react-virtual';
import { X, LocateFixed } from 'lucide-react';
import { ComboboxItem } from './BreedCombobox';
import { Spinner } from '../../../landing/components/Spinner';
import { cn } from '../../../../../../utils/tailwind';
export type ComboboxProps = {
  inputClassName?: string;
  query: string;
  error?: boolean;
  setQuery: Dispatch<SetStateAction<string>>;
  resetState?: () => void;
  placeholder?: string;
  onItemChange: (newItem: ComboboxItem) => void;
  itemClassName?: string;
  locatorClassName?: string;
  highlightedIndexClassName?: string;
  clearClassName?: string;
  items: ComboboxItem[];
  Icon?: FC<React.PropsWithChildren<unknown>>;
  initialItem?: ComboboxItem;
  isFetching?: boolean;
  toggleResults?: () => void;
  onLocatorClick?: () => void;
  onBlur?: () => void;
  expand?: boolean;
  height?: string;
};
export const Combobox = ({
  inputClassName,
  initialItem,
  resetState,
  error = false,
  onItemChange,
  onBlur,
  itemClassName,
  highlightedIndexClassName = 'bg-caribbeanGreen/20 text-darkGray',
  locatorClassName,
  clearClassName,
  query,
  Icon,
  placeholder = 'Start typing your address...',
  setQuery,
  isFetching = false,
  onLocatorClick,
  toggleResults,
  expand = false,
  items,
  height = '50px'
}: ComboboxProps) => {
  const listRef = useRef<HTMLUListElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: items?.length || 0,
    getScrollElement: () => listRef.current,
    estimateSize: useCallback(() => 44, []),
    overscan: 2
  });
  const {
    getInputProps,
    isOpen,
    getItemProps,
    getMenuProps,
    selectItem,
    reset,
    highlightedIndex,
    selectedItem
  } = useCombobox({
    items,
    onSelectedItemChange: async ({
      selectedItem: newSelectedItem
    }) => {
      const input = document.querySelector('[role=combobox]') as HTMLInputElement;
      input.blur();
      onItemChange(newSelectedItem);
    },
    itemToString: item => item?.label || '',
    inputValue: query,
    onInputValueChange: ({
      inputValue: newValue
    }) => {
      if (toggleResults) {
        toggleResults();
      }
      setQuery(newValue);
    },
    onHighlightedIndexChange: ({
      highlightedIndex: hlIndex
    }) => rowVirtualizer && rowVirtualizer?.scrollToIndex(hlIndex as number)
  });
  useEffect(() => {
    if (initialItem) {
      selectItem(initialItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className={clsx('relative flex w-full flex-col', expand && isOpen && 'mb-[150px]', Icon ? 'items-center' : '')} data-sentry-component="Combobox" data-sentry-source-file="Combobox.tsx">
      <div className={clsx('flex relative w-full items-center', {
      [`h-[${height}]`]: height
    })}>
        {Icon && <Icon />}
        <input {...getInputProps({
        type: 'text'
      })} id="combobox-input" data-cy="combobox-input" placeholder={placeholder} onBlur={() => {
        if (onBlur) {
          onBlur();
        }
        if (!query) {
          reset();
          if (resetState) {
            resetState();
          }
        }
      }} className={cn('w-full h-full border', 'placeholder:text-sm placeholder:text-darkGray/50 placeholder:font-new-hero', 'text-[16px] md:text-[14px] outline-primary text-wagGray truncate', 'data-[state=closed]:rounded-md data-[state=open]:rounded-t-md', isOpen ? 'rounded-t-md' : 'rounded-md', error ? 'border-red-500' : 'border-gray300', Icon ? 'px-9 ' : 'px-4', inputClassName)} />
        {query && !isFetching && <button type="button" aria-label="Clear" onClick={() => {
        if (resetState) {
          resetState();
        }
        reset();
      }} className={clsx('absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center h-[60%]', 'bg-white pl-2 right-2', clearClassName)}>
            <X className="h-5 w-5 text-gray200" aria-hidden="true" />
          </button>}
        {!query && onLocatorClick && !isFetching && <LocateFixed className={clsx('absolute right-3 top-[15px] flex w-5 h-5 items-center text-[#BBBBBB]', 'cursor-pointer', locatorClassName)} onClick={onLocatorClick} />}
        {isFetching && <Spinner className="absolute right-3 top-[8px] flex h-[70%] items-center text-caribbeanGreen" />}
      </div>
      <ul data-cy="combobox-list" className={clsx('absolute top-[49px] z-10 mx-auto h-auto max-h-[270px] md:max-h-[300px] w-full overflow-y-scroll scrollbar-thin scrollbar-thumb-wagBlue scrollbar-thumb-rounded border', isOpen && items?.length ? 'bg-white border-gray300' : 'bg-transparent border-transparent', isOpen ? 'rounded-b-md' : 'rounded-md')} {...getMenuProps({
      ref: listRef
    })}>
        {isOpen && <>
            <li key="total-size" style={{
          height: `${rowVirtualizer.getTotalSize()}px`
        }} />
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
          const item = items[virtualRow.index];
          return <li key={String(virtualRow.key)} className={clsx('absolute left-0 top-0 flex w-full items-center px-4 cursor-pointer hover:bg-caribbanGreen/10', isFetching ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100', highlightedIndex === virtualRow.index ? highlightedIndexClassName : 'bg-inherit text-darkGray/90', selectedItem && selectedItem === item ? 'font-[500]' : 'font-[400]', 'subpixel-antialiased', itemClassName)} {...getItemProps({
            index: virtualRow.index,
            item: item,
            style: {
              height: virtualRow.size,
              transform: `translateY(${virtualRow.start}px)`
            }
          })}>
                  <div className="w-full">
                    <p className="text-sm truncate">{item.label}</p>
                  </div>
                </li>;
        })}
          </>}
      </ul>
    </div>;
};