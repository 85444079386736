import React from 'react';
import { Calendar, CalendarProps } from '@/tw-components/ui/calendar';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { format } from 'date-fns';
import { DateFormatter } from 'react-day-picker';
import clsx from 'clsx';
type Props = {
  calendarProps?: CalendarProps;
  formatter?: DateFormatter;
  classNames?: CalendarProps['classNames'];
  id?: string;
};
const formatCaption: DateFormatter = (month, options) => <div className="flex flex-col" data-sentry-component="formatCaption" data-sentry-source-file="WalkerCalendar.tsx">
    <span aria-label="month" className="font-muli-bold text-[#272727] text-[14px]">
      {format(month, 'LLLL', {
      locale: options?.locale
    })}
    </span>
    <span className="font-muli text-[#818181] text-[12px] text-center" aria-label="year">
      {month.getFullYear()}
    </span>
  </div>;
export const WalkerCalendar = ({
  calendarProps,
  formatter = formatCaption,
  classNames,
  id = 'walker-availability-calendar'
}: Props) => <Calendar id={id} data-cy="date-picker" classNames={{
  months: 'w-full',
  tbody: '[&>tr]:justify-between',
  head: '[&>tr]:justify-between',
  nav_button: 'opacity-100',
  ...classNames
}} formatters={{
  formatCaption: formatter
}} fromMonth={new Date()} components={{
  IconLeft: () => <span className="bg-[#F6F6F6] w-[26px] h-[26px] p-1 flex place-items-center rounded-full absolute left-0 top-[-12px] cursor-pointer hover:bg-gray-200/60">
          <span className="rounded-full flex w-[14px] h-[14px] p-[2px] place-items-center mx-auto" style={{
      border: `1px solid #272727`
    }}>
            <ChevronLeftIcon className="h-4 w-4 text-black" />
          </span>
        </span>,
  IconRight: () => <span data-cy="date-picker-next-month" className={clsx('bg-[#F6F6F6] w-[26px] h-[26px] p-1 flex place-items-center right-[2px]', 'rounded-full absolute top-[-12px] cursor-pointer hover:bg-gray-200/60')}>
          <span className="rounded-full flex w-[14px] h-[14px] p-[2px] place-items-center mx-auto" style={{
      border: `1px solid #272727`
    }}>
            <ChevronRightIcon className="h-4 w-4 text-black" />
          </span>
        </span>
}} {...calendarProps} data-sentry-element="Calendar" data-sentry-component="WalkerCalendar" data-sentry-source-file="WalkerCalendar.tsx" />;