import { getBaseHeaders } from './getBaseHeaders';

import { HttpClient, HttpClientRequestConfig } from '../types';

export const get = (httpClient: HttpClient) => <THttpResponseData>(
  url: string,
  config: HttpClientRequestConfig = {},
) => {
  const requestConfig: HttpClientRequestConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...getBaseHeaders,
    },
  };

  return httpClient.get<THttpResponseData>(url, requestConfig);
};
