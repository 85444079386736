import { format, parse } from 'date-fns';
import { SelectedDate } from '../components/DatePicker';

export const AvailableTimesSingleDay = [
  {
    id: 7,
    label: '6:00 AM - 9:00 AM',
    start_hour: 6,
    start_minute: 0,
    end_hour: 9,
    end_minute: 0,
    end_time: 0,
  },
  {
    id: 8,
    label: '9:00 AM - 12:00 PM',
    start_hour: 9,
    start_minute: 0,
    end_hour: 12,
    end_minute: 0,
    end_time: 0,
  },
  {
    id: 9,
    label: '12:00 PM - 3:00 PM',
    start_hour: 12,
    start_minute: 0,
    end_hour: 15,
    end_minute: 0,
    end_time: 0,
  },
  {
    id: 10,
    label: '3:00 PM - 6:00 PM',
    start_hour: 15,
    start_minute: 0,
    end_hour: 18,
    end_minute: 0,
    end_time: 0,
  },
  {
    id: 11,
    label: '6:00 PM - 9:00 PM',
    start_hour: 18,
    start_minute: 0,
    end_hour: 21,
    end_minute: 0,
    end_time: 0,
  },
];

export const parseDate = (date: string) => parse(date, 'yyyy-MM-dd', new Date());

export const formatDate = (date: Date) => date && format(date, 'yyyy-MM-dd');

export const getTimeValue = (num: number) => {
  if (!num) return null;
  if (num === 6 || num === 9) return `0${num}:00:00`;
  return `${num}:00:00`;
};

export const getCaption = (value: SelectedDate) => {
  let text = '';
  if (value instanceof Date) {
    text = format(value, 'PPP');
  } else if (!value?.from && !value?.to) {
    text = 'Select dates';
  } else {
    text = `
      ${value?.from instanceof Date ? value?.from.toLocaleDateString() : 'Select start date'}
      - ${value?.to instanceof Date ? value?.to.toLocaleDateString() : 'Select end date'}
    `;
  }

  return text;
};

export const getTimeSlotId = (selectedTime: string) => {
  const startTimeId = AvailableTimesSingleDay.find((timeSlot) => timeSlot.start_hour
                    === Number(selectedTime?.split(':')[0]))?.id.toString();
  return startTimeId;
};

export const isDateRangeValid = (fromDate: Date, toDate: Date) => {
  let error = null;

  if (!fromDate) {
    error = `Invalid start date`;
    return error;
  }

  if (!toDate) {
    error = `Invalid end date`;
    return error;
  }

  const currentDate = formatDate(new Date());
  const selectedFromDate = fromDate && formatDate(fromDate);
  const selectedToDate = toDate && formatDate(toDate);

  if (currentDate > selectedFromDate) {
    error = 'Please select a valid date';
    return error;
  }

  if (currentDate > selectedToDate || selectedFromDate > selectedToDate) {
    error = 'Please select a valid date range';
    return error;
  }

  return error;
};

export const isSingleDateValid = (date: string) => {
  let error = null;

  if (!date) {
    error = 'Please select a date';
    return error;
  }

  const parsedDate = parseDate(date);

  const currentDate = formatDate(new Date());
  const selectedDate = formatDate(parsedDate);

  if (currentDate > selectedDate) {
    error = 'Please select a valid date';
    return error;
  }

  return error;
};
