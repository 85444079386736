import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import clsx from 'clsx';
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;

// Define a new interface that extends the original props and includes our custom prop
interface ExtendedPopoverContentProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  showArrow?: boolean;
}
const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, ExtendedPopoverContentProps>(({
  children,
  className,
  align = 'center',
  sideOffset = 4,
  showArrow = true,
  ...props
}, ref) => <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content ref={ref} align={align} sideOffset={sideOffset} className={clsx('z-50 w-72 rounded-md border border-gray200 bg-white p-4 text-slate-950 shadow-lg outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', className)} {...props}>
      {children}
      {showArrow && <>
          <PopoverPrimitive.Arrow className="fill-gray200 w-7 h-3 custom-arrow-shadow2" />
          <PopoverPrimitive.Arrow className="fill-white border-gray200 w-7 h-3 custom-arrow-shadow" />
        </>}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
export { Popover, PopoverTrigger, PopoverContent };