'use client';

import React, { useState, useEffect } from 'react';
import { MapPin } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { getGeocoding } from '../../../../../../hooks/api/getGeocoding';
import { useFiltersStore } from '../../../../context';
import { Combobox } from './Combobox';
import { useDebouncedValue } from '../../../../../../hooks/useDebouncedValue';
import { formatAddress, formatAddressFromOwnerAddress } from '../../../../helpers/formatAddress';
import { validateAddress } from '../validation/validateAddress';
import { getReverseGeocoding } from '../../../../../../hooks/api/getReverseGeocoding';
import { QUERY_KEYS } from '../../../../constants/query-keys';
import { cn } from '../../../../../../utils/tailwind';
export type SearchComboboxProps = {
  iconClassName?: string;
  itemClassName?: string;
  locatorClassName?: string;
  inputClassName?: string;
  clearClassName?: string;
  height?: string;
  onAddressSelect?: (address: {
    addressLine1: string;
    city: string;
    administrativeAreaLevel1: string;
    postalCode: string;
    coordinates?: [number, number];
  }) => void;
  updateFiltersStore?: boolean;
  showLocationButton?: boolean;
};
export const SearchCombobox = ({
  iconClassName,
  height = '50px',
  inputClassName,
  locatorClassName,
  onAddressSelect,
  updateFiltersStore = true,
  showLocationButton = true,
  ...rest
}: SearchComboboxProps) => {
  const setFilters = useFiltersStore(state => state.setFilters);
  const setSearch = useFiltersStore(state => state.setSearch);
  const {
    coordinates,
    address
  } = useFiltersStore(state => state.search);
  const [showLocation, setShowLocation] = useState(false);
  // Track if user is actively editing the address
  const [isEditing, setIsEditing] = useState(false);

  // Initialize query from persisted address if it exists
  const [query, setQuery] = useState(() => {
    // If we have a valid address in the store, use it as the initial query
    if (updateFiltersStore && validateAddress(address).success) {
      return formatAddressFromOwnerAddress(address) || '';
    }
    return '';
  });

  // Effect to update query when address changes, but only if user is not editing
  useEffect(() => {
    if (!isEditing && updateFiltersStore && validateAddress(address).success) {
      const formattedAddress = formatAddressFromOwnerAddress(address);
      if (formattedAddress) {
        setQuery(formattedAddress);
      }
    }
  }, [address, updateFiltersStore, isEditing]);
  const debouncedValue = useDebouncedValue(query);
  const [error, setError] = useState(false);
  const parsedAddress = updateFiltersStore ? validateAddress(address).success && formatAddressFromOwnerAddress(address) : null;
  const {
    data: geocodingResponse,
    isFetching
  } = useQuery({
    queryFn: () => getGeocoding({
      location: debouncedValue,
      queryParams: {
        types: 'address',
        limit: 5
      }
    }),
    queryKey: [QUERY_KEYS.GEOCODING_SEARCH, debouncedValue],
    enabled: debouncedValue.length >= 3 && query.length >= 3 && query !== parsedAddress
  });
  const {
    data: reverseGeocodingResponse,
    isFetching: isFetchingReverseGeocoding
  } = useQuery({
    queryFn: () => getReverseGeocoding({
      coordinates: [coordinates?.[0], coordinates?.[1]]
    }),
    queryKey: [QUERY_KEYS.REVERSE_GEOCODING_SEARCH, coordinates?.[0], coordinates?.[1]],
    enabled: !!coordinates?.[0] && !!coordinates?.[1]
  });
  const autocompleteAddresses = (showLocation && reverseGeocodingResponse ? reverseGeocodingResponse : geocodingResponse?.features) || [];
  return <Combobox height={height} items={autocompleteAddresses?.map(place => ({
    label: formatAddress(place)?.placeName,
    slug: place.id
  }))} inputClassName={inputClassName} locatorClassName={locatorClassName} setQuery={value => {
    setIsEditing(true);
    setQuery(value);
  }} query={query} placeholder="Start typing your address or zip code" initialItem={updateFiltersStore ? {
    label: parsedAddress,
    slug: null
  } : undefined} toggleResults={() => setShowLocation(false)}
  // When an item is selected from the dropdown
  onItemChange={async newSelectedItem => {
    if (!newSelectedItem?.slug) return;
    const selectedAddress = autocompleteAddresses.find(place => place.id === newSelectedItem.slug);
    const formattedAddress = formatAddress(selectedAddress).address;
    if (selectedAddress && selectedAddress.center) {
      if (updateFiltersStore) {
        setFilters({
          longitude: selectedAddress.center[0],
          latitude: selectedAddress.center[1],
          longitudeMarkerHome: selectedAddress.center[0],
          latitudeMarkerHome: selectedAddress.center[1],
          page: 1
        });
        setSearch({
          address: formattedAddress
        });
      }
      if (onAddressSelect) {
        onAddressSelect({
          addressLine1: formattedAddress.addressLine1,
          city: formattedAddress.city,
          administrativeAreaLevel1: formattedAddress.administrativeAreaLevel1,
          postalCode: formattedAddress.postalCode,
          coordinates: [selectedAddress.center[0], selectedAddress.center[1]]
        });
      }
      setError(false);
    }
  }} Icon={() => <MapPin className={cn('absolute bottom-[9px] left-[0px] z-[3] w-[16px] object-cover text-caribbeanGreen', iconClassName)} />} isFetching={isFetching || isFetchingReverseGeocoding} onLocatorClick={showLocationButton ? () => {
    if (coordinates) {
      const comboboxInput = document.getElementById('combobox-input') as HTMLInputElement;
      if (comboboxInput) {
        comboboxInput.click();
      }
      setShowLocation(true);
    } else if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setSearch({
          coordinates: [position.coords.longitude, position.coords.latitude]
        });
        const comboboxInput = document.getElementById('combobox-input') as HTMLInputElement;
        if (comboboxInput) {
          comboboxInput.click();
        }
        setShowLocation(true);
      });
    }
  } : undefined} error={error} onBlur={() => {
    // Reset editing state when input loses focus
    setIsEditing(false);
    if (query && !address?.addressLine1) {
      setError(true);
    }

    // If query is empty but we have an address, clear the address
    if (!query && address?.addressLine1) {
      setSearch({
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          postalCode: null,
          administrativeAreaLevel1: null,
          country: null
        },
        coordinates: null
      });
    }
  }} resetState={() => {
    setIsEditing(false);
    setQuery('');
    // Clear the address in the persisted store
    setSearch({
      address: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        postalCode: null,
        administrativeAreaLevel1: null,
        country: null
      },
      coordinates: null
    });
    setFilters({
      longitude: null,
      latitude: null,
      latitudeMarkerHome: null,
      longitudeMarkerHome: null
    });
  }} {...rest} data-sentry-element="Combobox" data-sentry-component="SearchCombobox" data-sentry-source-file="SearchCombobox.tsx" />;
};