'use client';

import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { DateFormatter, DayPickerRangeProps, DayPickerSingleProps, DayPickerMultipleProps } from 'react-day-picker';
import { TWButton } from '../../../../../../tw-components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../../tw-components/ui/popover';
import { WalkerCalendar } from '../../../../../PCGProfile/components/WalkerSchedule/WalkerCalendar';
import { useBookingStore, useFiltersStore } from '../../../../context';
import { ScheduleIcon } from '../../../../../../icons/ScheduleIcon';
import { cn } from '../../../../../../utils/tailwind';
const formatCaption: DateFormatter = (month, options) => <div className="flex flex-col" data-sentry-component="formatCaption" data-sentry-source-file="BookingDatePicker.tsx">
    <span aria-label="month" className="font-new-hero text-[#272727] text-[14px]">
      {format(month, 'LLLL', {
      locale: options?.locale
    })}
    </span>
    <span className="font-new-hero text-[#818181] text-[12px] text-center" aria-label="year">
      {month.getFullYear()}
    </span>
  </div>;
type Props = {
  calendarProps: DayPickerRangeProps | DayPickerSingleProps | DayPickerMultipleProps;
  caption: string;
  triggerButtonClass?: string;
  align?: 'center' | 'start' | 'end';
  sideOffset?: number;
  iconColor?: string;
  triggerButtonId?: string;
  dateCaptionClassName?: string;
  showSaveButton?: boolean;
  clearDates?: () => void;
  showArrow?: boolean;
  'data-end-date-input'?: boolean;
};
export const BookingDatePicker = ({
  triggerButtonClass,
  triggerButtonId = 'start-date-btn',
  sideOffset = 0,
  iconColor = '#00BF8F',
  align = 'center',
  caption,
  calendarProps,
  showSaveButton,
  clearDates,
  dateCaptionClassName,
  showArrow = false,
  'data-end-date-input': isEndDateInput
}: Props) => {
  const setSearch = useFiltersStore(state => state.setSearch);
  const schedule = useBookingStore(state => state.schedule);
  const setFilters = useFiltersStore(state => state.setFilters);
  const {
    startDate,
    endDate
  } = useFiltersStore(state => state.filters);
  const triggerButtonRef = useRef(null);
  const isOnDemand = useBookingStore(state => state.isOnDemand);
  const [error, setError] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const selected = () => {
    if (calendarProps?.selected && 'from' in calendarProps?.selected) {
      return calendarProps.selected.from;
    }
    if (calendarProps.mode === 'single' && calendarProps?.selected instanceof Date) {
      return calendarProps.selected;
    }
    if (isOnDemand && !schedule.repeat && schedule.selectedDays?.[0]) {
      return parseISO(schedule.selectedDays[0]);
    }
    if (Array.isArray(calendarProps?.selected) && calendarProps.selected[0] instanceof Date) {
      return calendarProps.selected[0];
    }
    return undefined;
  };
  const [hasHydrated, setHasHydrated] = useState(false);
  useEffect(() => {
    setHasHydrated(true);
  }, []);
  const validateSingleMode = () => {
    setError(!startDate);
  };
  const validateMultipleMode = () => {
    setError(schedule.selectedDays.length === 0);
  };
  const validateRangeMode = () => {
    setError(!startDate || !endDate);
  };
  const validateCalendar = mode => {
    switch (mode) {
      case 'single':
        validateSingleMode();
        break;
      case 'multiple':
        validateMultipleMode();
        break;
      case 'range':
        validateRangeMode();
        break;
      default:
        setError(false);
    }
  };
  const dateCaption = hasHydrated ? caption : 'Select your date';
  return <Popover onOpenChange={open => {
    if (!open) {
      validateCalendar(calendarProps.mode);
    } else {
      setError(false);
    }
    setSearch({
      enabled: !open
    });
    setOpen(open);
  }} data-sentry-element="Popover" data-sentry-component="BookingDatePicker" data-sentry-source-file="BookingDatePicker.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="BookingDatePicker.tsx">
        <TWButton ref={triggerButtonRef} id={triggerButtonId} data-cy="date-picker-trigger-button" data-end-date-input={isEndDateInput} variant="calendar-booking-journey" size="lg" className={clsx('justify-start text-wagGray text-left w-full bg-white pl-0', 'focus-visible:outline outline-1 outline-caribbeanGreen', error ? 'border-red-500' : 'border-gray200', !caption && 'text-muted-foreground', isOpen && !showArrow ? '!rounded-t-md !rounded-b-none' : '!rounded-md', triggerButtonClass)} data-sentry-element="TWButton" data-sentry-source-file="BookingDatePicker.tsx">
          <ScheduleIcon color={iconColor} data-sentry-element="ScheduleIcon" data-sentry-source-file="BookingDatePicker.tsx" />
          <span className={cn('text-[12px] font-new-hero text-darkGray mt-0.5 font-[400] pl-[2px]', hasHydrated && selected() ? 'opacity-100' : 'opacity-50', dateCaptionClassName)}>
            {dateCaption}
          </span>
        </TWButton>
      </PopoverTrigger>
      <PopoverContent className={clsx('!p-[2px] mt-[-1px] flex flex-col items-center w-min border !border-gray300', isOpen && !showArrow ? '!rounded-b-md !rounded-t-none' : '!rounded-md')} align={align} sideOffset={sideOffset} showArrow={showArrow} data-sentry-element="PopoverContent" data-sentry-source-file="BookingDatePicker.tsx">
        <WalkerCalendar id="booking-journey-calendar" formatter={formatCaption} calendarProps={{
        ...calendarProps,
        defaultMonth: selected()
      }} classNames={{
        cell: '!mx-0 !p-0',
        tbody: '!m-0 !p-0',
        day: 'h-9 !w-[38px]',
        day_range_start: '!border !rounded-tl-full !rounded-bl-full',
        day_range_end: '!bg-wagBlue !text-white !border-[3px] !border-red-500 !rounded-tr-full !rounded-br-full',
        day_selected: clsx('!bg-wagBlue !text-white border !border-red-500 !m-0', calendarProps?.mode === 'range' ? '!rounded-none' : '!rounded-full')
      }} data-sentry-element="WalkerCalendar" data-sentry-source-file="BookingDatePicker.tsx" />
        {showSaveButton && <TWButton size="pill" variant="booking-journey" onClick={() => {
        if (triggerButtonRef.current) {
          triggerButtonRef.current.click();
        }
      }} className={clsx('w-[90%] h-[42px] mt-3 mb-5', '!font-[500] text-xs')}>
            Select Date
            {schedule.selectedDays.length > 1 && 's'}
          </TWButton>}
        <button className="underline text-xs mb-3 text-[#818181]" type="button" onClick={() => {
        if (clearDates) {
          clearDates();
        } else {
          // Check if this is an end date input
          if (isEndDateInput) {
            // Clear only the end date
            setFilters({
              endDate: null
            });
          } else if (calendarProps.mode === 'single') {
            // For single mode, this is a start date picker
            setFilters({
              startDate: null
            });
          } else {
            // For multiple or range mode, clear both dates
            setFilters({
              startDate: null,
              endDate: null
            });
          }
        }
      }}>
          Clear date{calendarProps.mode === 'single' ? '' : 's'}
        </button>
      </PopoverContent>
    </Popover>;
};