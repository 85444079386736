/* eslint-disable max-len */

import queryString from 'query-string';
import { SortBy } from '../containers/CityPageContainer/CityPageContainerContext';
import { CityPages } from './app';

/* eslint-disable prefer-destructuring */
export const WAG_API_URL = process.env.NEXT_PUBLIC_WAG_API_URL;
export const WAG_CONTENT_API_URL = process.env.NEXT_PUBLIC_WAG_CONTENT_API_URL;

// wagapi endpoints
export const ENDPOINT_TRAINERS_BY_LOCATION_GET = ({ state, city }) => `/api/v5/web/providers/${state}/${city}/training`;
export const ENDPOINT_CITY_DIRECTORY_GET = ({ state, city }) => `/api/geo-articles/v1/directory/${state}/${city}`;
export const createGetCaregiversByLocationEndpoint = ({ state, city }) => `/api/v5/web/providers/${state}/${city}/boarding`;
export const createGetWalkersByLocationEndpoint = ({ state, city }) => `/api/v5/web/providers/${state}/${city}/walk`;

export const createGetBrowseAndBookWalkerProfiles = (
  {
    latitude,
    longitude,
    distance = CityPages.DefaultWalkerDistance,
    page = 1,
    perPage = CityPages.OrganicProfilesPerPage,
    service = 'walk',
    datetimeMin = '',
    datetimeMax = '',
    // price in cents
    priceMin = 1000,
    priceMax = 9000,
    promoted = false,
    sortBy = [{ column: CityPages.DefaultSortColumn }] as [SortBy],
    requiredWalkerProfileFlags = [],
    excludedWalkerProfileFlags = [],
  },
) => {
  const sortByQueryString = sortBy.map((obj, i) => Object.keys(obj).map((key) => `sortBy[${i}][${key}]=${obj[key]}`).join('&')).join('&');
  const url = queryString.stringifyUrl({
    url: '/api/v5/web/providers/search',
    query: {
      latitude,
      longitude,
      distanceMiles: distance,
      page,
      perPage,
      service,
      priceMin,
      priceMax,
      promoted,
      requiredWalkerProfileFlags,
      excludedWalkerProfileFlags,
      ...(datetimeMin && {
        datetimeMin,
      }),
      ...(datetimeMax && {
        datetimeMax,
      }),
    },
  }, { arrayFormat: 'bracket' });
  const urlAndSortByQueryString = `${url}&${sortByQueryString}`;
  return urlAndSortByQueryString;
};

export const createGetCityPageReviews = (
  {
    latitude,
    longitude,
    distanceMiles,
    service = 'walk',
    perPage = 10,
  },
) => (
  queryString.stringifyUrl({
    url: 'api/v5/web/providers/reviews',
    query: {
      latitude,
      longitude,
      distanceMiles,
      service,
      perPage,
    },
  })
);

// wagcontent endpoints
export const createGetTrainingCityArticleEndpoint = ({ state, city }) => `/api/articles/v1/training/${state}/${city}`;
export const ENDPOINT_STATE_DIRECTORY_GET = ({ state }) => `/api/geo-articles/v1/directory/${state}`;
export const ENDPOINT_CITY_OVERNIGHT_ARTICLE_GET = ({ state, city }) => `/api/articles/v1/dog-boarding/${state}/${city}`;
export const createGetCityWalkingArticleEndpoint = ({ state, city }) => `/api/articles/v1/dog-walking/${state}/${city}`;
export const createGetServicePageEndpoint = ({ slug }) => `/api/static-pages/v1/static-service-page/${slug}`;

// https://github.com/waglabs/wag-react/tree/master/packages/common/api#dynamic-urls-on-react-hooks
export enum WagApiEndpoint {
  GetBreeds = '/dog/common-breeds',
  GetCaregiversByLocation = '/api/v5/web/providers/{{state}}/{{city}}/boarding',
  GetTrainersByLocation = '/api/v5/web/providers/{{state}}/{{city}}/training',
  GetWalkersByLocation = '/api/v5/web/providers/{{state}}/{{city}}/walk',
  GetWalkerProfileByPromoCode = '/pet-care-provider/{{promoCode}}/profile',
  GetWalkerProfileReviewsByPromoCode = '/pet-care-provider/{{promoCode}}/reviews',
  GetWalkerProfileGalleryByPromoCode = '/pet-care-provider/{{promoCode}}/gallery',
  GetWalkerProfileRecommendationsByPromoCode = '/pet-care-provider/{{promoCode}}/recommendations',
  GetConditionArticleBySlug = '/api/health-articles/v1/condition/{{slug}}',
  GetTreatmentArticleBySlug = '/api/health-articles/v1/treatment/{{slug}}',
  GetSymptomArticleBySlug = '/api/health-articles/v1/symptom/{{slug}}',
  PostWalkerTaxPreferences = '/api/v5/walker/tax_preferences/external',
  PostOwnerPets = 'api/v6/owners/{{ownerId}}/pets',
  PostOwnerSignup = '/api/v5/owner',
  PostRegisterOwner = '/api/v6/owners',
  PatchOwner = '/api/v6/owners/{{ownerId}}',
  PutOwnerAddress = '/api/v6/owners/{{ownerId}}/address',
  PostDirectBookingRequest = '/api/v4/owner/{{ownerId}}/booking_request',
  PostOwnerPaymentMethod = 'api/v5/owner/{{ownerId}}/payment_method',
  PostGiftCard = '/api/v5/web/gift',
  GetEstimatedPrice = 'api/v6/web/providers/{{walkerUuid}}/estimate-price',
  PostGiftCardStatus = '/api/v5/web/gift/status',
  GetServiceProvidersByZipCode = '/api/v5/web/providers/{{zipCode}}/{{service}}',
  PostWalkerSignup = '/api/v6/walkers',
  PostSession = '/api/v6/authentication/session',
  PostCode = '/api/v6/authentication/code',
  PostVerify = '/api/v6/authentication/verify',
  GetWalkingPreferences = '/api/v6/web/walk_type/{{serviceTypeIdentifier}}/preferences',
  GetWalkerServicesOffered = '/api/v6/walkers/{{walkerUuid}}/offered-services',
  GetWalkerTimeWindows = '/dog/time_windows',
  PostMediaUploadUrl = '/api/v6/_operations/create-media-upload-url',
  PutUploadMedia = '/api/v6/pets/{{identifier}}/image',
  GetPartnershipLink = 'api/v6/promo-codes/{{promoCode}}/partnership-link',
}

export enum WalkerProfileV6Endpoints {
  GetWalkerProfile = 'api/v6/walkers/{{identifier}}/profile',
  GetWalkerAchievements = 'api/v6/walkers/{{identifier}}/achievements',
  GetWalkerActivity = 'api/v6/walkers/{{identifier}}/activity',
  GetWalkerReviews = 'api/v6/walkers/{{identifier}}/reviews',
  GetWalkerAvailability = 'api/v6/walkers/{{identifier}}/availability',
  GetWalkerHomeInfo = 'api/v6/walkers/{{identifier}}/home-info',
  GetWalkerHomeInfoPhotos = 'api/v6/walkers/{{identifier}}/home-info/photos',
  GetWalkerProfileRecommendations = 'api/v6/walkers/{{identifier}}/profile-recommendations',
  GetWalkerGallery = 'api/v6/walkers/{{identifier}}/gallery',
  GetWalkerOfferedServicesGallery = 'api/v6/walkers/{{identifier}}/offered-services/{{category}}/gallery',
  GetWalkerOfferedServicesCategory = 'api/v6/walkers/{{identifier}}/offered-services/{{category}}',
  GetWalkerOfferedServices = 'api/v6/walkers/{{identifier}}/offered-services',
  GetWalkerServicesAddons = 'api/v6/walkers/{{identifier}}/offered-service-addons',
  GetWalkerLocation = 'api/v6/walkers/{{identifier}}/location',
  GetWalkerSpecialties = 'api/v6/walkers/{{identifier}}/specialties',
}

export enum WagContentApiEndpoint {
  GetAffiliateProductArticle = 'api/product-articles/v1/{{slug}}',
  GetAffiliateProductReviewArticle = 'api/product-articles/v1/reviews/{{slug}}',
  GetAnimalConditionArticleBySlug = '/api/health-articles/v1/{{animal}}/condition/{{slug}}',
  GetConditionArticleVetAdvice = '/api/health-articles/v1/{{animal}}/condition/{{slug}}/vet-advice',
  GetConditionArticleBySlug = '/api/health-articles/v1/condition/{{slug}}/vet-advice',
  GetAnimalSymptomArticleBySlug = '/api/health-articles/v1/{{animal}}/symptom/{{slug}}',
  GetSymptomArticleVetAdvice = '/api/health-articles/v1/{{animal}}/symptom/{{slug}}/vet-advice',
  GetSymptomArticleBySlug = '/api/health-articles/v1/symptom/{{slug}}/vet-advice',
  GetAnimalTreatmentArticleBySlug = '/api/health-articles/v1/{{animal}}/treatment/{{slug}}',
  GetTreatmentArticleVetAdvice = '/api/health-articles/v1/{{animal}}/treatment/{{slug}}/vet-advice',
  GetTreatmentArticleBySlug = '/api/health-articles/v1/treatment/{{slug}}/vet-advice',
  GetDogBreedAssets = 'api/v6/breeds',
  GetAWSSIgnature = 'api/ugc/v1/signed-request',
  GetPastQuestions = 'api/advice/v1/premium-question',
  GetMedications = 'api/attributes/v1/autocomplete/medications',
  GetSymptoms = 'api/attributes/v1/autocomplete/symptoms',
  GetPremiumStatusForOwner = 'api/advice/v1/premium-status',
  GetPartner = 'api/partners/v1/{{slug}}',
  GetPartnerSlugs = 'api/partners/v1/slugs',
  GetCityWalkingSlugs = 'api/geo-articles/v1/dog-walking/slugs',
  GetBehaviorArticle = 'api/dailywag/behavior-guides/v1/{{slug}}',
  GetBehaviorArticleSlugs = 'api/dailywag/behavior-guides/v1/slugs',
  PostBehaviorArticleComments = 'api/dailywag/behavior-guides/v1/{{slug}}/comments',
  GetBehaviorArticleComments = 'api/dailywag/behavior-guides/v1/{{slug}}/comments',

  GetSenseArticle = 'api/dailywag/sense-guides/v1/{{slug}}',
  GetSenseArticleSlugs = 'api/dailywag/sense-guides/v1/slugs',
  PosSenseArticleComments = 'api/dailywag/sense-guides/v1/{{slug}}/comments',
  GetSenseArticleComments = 'api/dailywag/sense-guides/v1/{{slug}}/comments',

  GetTrainingGuide = 'api/training-guides/v1/{{animalTypeSlug}}/{{slug}}',
  GetTrainingGuideSlugs = 'api/training-guides/v1/slugs',
  GetTrainingGuideUGC = 'api/training-guides/v1/{{animalTypeSlug}}/training/{{slug}}/ugc',
  PostTrainingGuideQuestion = 'api/training-guides/v1/{{animalTypeSlug}}/training/{{slug}}/questions',
  PostTrainingGuideQuestionComment = 'api/training-guides/v1/training/comment',

  PostAskAnExpertForm = 'api/advice/v1/premium-question',
  PostDogWalkerApplication = 'api/static-pages/v1/dog-walker',
  PostHealthArticleExperience = 'api/health-articles/v1/health-experience',
  PostTokenAuth = 'api/account/v1/token-auth',
  PostBehavioristOrganicForm = 'api/advice/v1/animal-expert-question',
  DailyWag = 'api/dailywag/v1/daily/{{slug}}',
  DailyWagBlogArticleComments = 'api/dailywag/blog-articles/v1/{{slug}}/comments',
  WellnessArticle = 'api/wellness-articles/v1/wellness/{{slug}}',

  GetNeighborhoodArticle = 'api/geo-articles/v1/{{state}}/{{city}}/{{neighborhood}}',
}
