import DogIcon from '../../../../assets/dog.svg';
import CatIcon from '../../../../assets/cat.svg';
import QuickMatchIcon from '../../../../assets/quick-match.svg';
import TopCaregiverIcon from '../../../../assets/top-caregivers.svg';

export const petType = [
  {
    label: 'Dog',
    slug: 'dog',
    info: null,
    icon: DogIcon,
  },
  {
    label: 'Cat',
    slug: 'cat',
    info: null,
    icon: CatIcon,
  },
] as const;

export type PetType = typeof petType[number];

export const petSize = [
  {
    label: 'Small',
    slug: 'small',
    info: '5 - 20 lbs',
    icon: null,
  },
  {
    label: 'Medium',
    slug: 'medium',
    info: '21 - 50 lbs',
    icon: null,
  },
  {
    label: 'Large',
    slug: 'large',
    info: '51 - 99 lbs',
    icon: null,
  },
  {
    label: 'XL',
    slug: 'extraLarge',
    info: '100 lbs +',
    icon: null,
  },
] as const;

export type PetSize = typeof petSize[number];

export const petAge = [
  {
    label: 'Puppy',
    slug: '1',
    info: '0 - 1 years',
    icon: null,
  },
  {
    label: 'Adult',
    slug: '4',
    info: '2 - 8 years',
    icon: null,
  },
  {
    label: 'Senior',
    slug: '8',
    info: '9 years +',
    icon: null,
  },
] as const;

export type PetAge = typeof petAge[number];

export const petSpayed = [
  {
    label: 'Yes',
    slug: 'yes',
    info: null,
    icon: null,
  },
  {
    label: 'No',
    slug: 'no',
    info: null,
    icon: null,
  },
  {
    label: 'Not Sure',
    slug: 'unspecified',
    info: null,
    icon: null,
  },
] as const;

export type PetSpayed = typeof petSpayed[number];

export const petGender = [
  {
    label: 'Male',
    slug: 'male',
    info: null,
    icon: null,
  },
  {
    label: 'Female',
    slug: 'female',
    info: null,
    icon: null,
  },
] as const;

export type PetGender = typeof petGender[number];

export const serviceLength = [
  {
    label: '20 Mins',
    slug: '20',
    info: null,
    icon: null,
  },
  {
    label: '30 Mins',
    slug: '30',
    info: null,
    icon: null,
  },
  {
    label: '60 Mins',
    slug: '60',
    info: null,
    icon: null,
  },
] as const;

export type TServiceLength = typeof serviceLength[number];

export const serviceTiming = [
  {
    label: 'Schedule',
    slug: 'schedule',
    info: null,
    icon: null,
  },
  {
    label: 'ASAP',
    slug: 'asap',
    info: null,
    icon: null,
  },
];

export type TServiceTiming = typeof serviceTiming[number];

export const fillStrategy = [
  {
    label: 'Find a Quick Match',
    slug: '1',
    info: 'We’ll match you with the best available Caregiver based on your profile.',
    icon: QuickMatchIcon,
  },
  {
    label: 'Choose From Top Caregivers',
    slug: '5',
    info: 'Choose between Caregivers who’ve opted in to accept your request.',
    icon: TopCaregiverIcon,
  },
];

export type TFillStrategy = typeof serviceTiming[number];

export type FilterItems = Readonly<
  | PetType[]
  | PetAge[]
  | PetSize[]
  | PetSpayed[]
  | PetGender[]
  | TServiceLength[]
  | TServiceTiming[]
  | TFillStrategy[]
>;
