import { parseISO } from 'date-fns';

// Get today's date with time set to midnight
export const getToday = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

// Check if a date string represents a date in the past
export const isDateInPast = (dateString: string): boolean => {
  if (!dateString) return false;
  const date = parseISO(dateString);
  return date < getToday();
};

// Filter an array of date strings to only include future dates
export const filterFutureDates = (dates: string[]): string[] => {
  if (!dates || !dates.length) return [];
  return dates.filter(date => !isDateInPast(date));
};
