import { getBaseHeaders } from './getBaseHeaders';

import { HttpClient, HttpClientRequestConfig } from '../types';

export const patch = (httpClient: HttpClient) => <TData, THttpResponseData>(
  url: string,
  data: TData,
  config: HttpClientRequestConfig = {},
) => {
  const requestConfig: HttpClientRequestConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...getBaseHeaders,
    },
  };

  return httpClient.patch<TData, THttpResponseData>(url, data, requestConfig);
};
