/* eslint-disable camelcase */

import { Mapbox } from '../../../constants/app';
import { ENV_MAPBOX_API_KEY } from '../../../constants/envs';
import { tryCatch } from '../../../utils/tryCatch';
import { httpGet } from '../../../wag-react/common/api/api';

type SearchType = 'address' | 'place' | 'district' | 'postcode';

type Props = {
  location: string;
  queryParams?: {
    limit?: number;
    types?: SearchType | SearchType[];
  };
}

export type GeocodingResponse = {
  type: string;
  query: string[];
  features: Array<{
    id: string;
    type: string;
    place_type: string[];
    place_name?: string;
    properties?: {
      accuracy: 'rooftop' | 'parcel' | 'point' | 'interpolated' | 'intersection' | 'street';
      mapbox_id: string;
    };
    relevance: number;
    text: string;
    center: number[];
    bbox: number[];
    geometry: {
      type: string;
      coordinates: number[];
    };
    context: Array<{
      id: string;
      mapbox_id: string;
      wikidata: string;
      short_code: string;
      text: string;
    }>;
  }>;
  attribution: string;
}

export const getGeocoding = async ({
  location,
  queryParams = {
    limit: 1,
    types: 'place',
  },
}: Props) => {
  const geoCodingUrl = Mapbox.Geocoding.replace('{{location}}', location);

  const [response, err] = await tryCatch(
    () => httpGet<GeocodingResponse>(geoCodingUrl, {
      params: {
        access_token: ENV_MAPBOX_API_KEY,
        autocomplete: true,
        country: 'US',
        proximity: 'ip',
        ...queryParams,
      },
    }));

  if (err) {
    throw err;
  }

  return response.data;
};
