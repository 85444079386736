import React from 'react';
import { cn } from '../../../../../../utils/tailwind/cn';
type Props = {
  title: string | {
    bold?: string;
    regular?: string;
  } | null;
  className?: string;
};
export const FilterTitle = ({
  title,
  className
}: Props) => {
  const renderTitle = () => {
    if (typeof title === 'string') {
      return <span className="font-[500]">{title}</span>;
    } else if (title && typeof title === 'object') {
      return <>
          {title.bold && <span className="font-[500]">{title.bold}</span>}
          {title.regular && <span className="font-[400]">{title.regular}</span>}
        </>;
    }
  };
  return <p className={cn('w-full text-darkGray font-new-hero text-xs sm:mb-1 md:mb-[2px]', className)} data-sentry-component="FilterTitle" data-sentry-source-file="FilterTitle.tsx">
      {renderTitle()}
    </p>;
};