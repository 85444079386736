import { GeocodingResponse } from '../../../hooks/api/getGeocoding';
import { OwnerAddress } from '../types/putOwner';

type FormatAddressResponse = { placeName: string; address: OwnerAddress };

const formatNumber = (num: number | string): string => {
  if (!num) return '';
  if (Number.isNaN(num)) return '';
  return num?.toString();
};

export const formatAddress = (
  place: GeocodingResponse['features'][number],
): FormatAddressResponse | null => {
  const searchByZipCode = place?.id?.includes('postcode');
  const searchByPlace = place?.id?.includes('place');
  if (!place.place_name) return null;
  const placeName = place.place_name?.split(',');
  const stateAbbreviation = place?.context?.find?.(
    (item) => item?.id?.includes?.('region'))?.short_code?.split('-')?.[1];
  const city = (searchByZipCode || searchByPlace
    ? place.context.find((item) => item?.id?.includes?.('district'))?.text
    : placeName?.[1].trim())
     || place.context.find((item) => item?.id?.includes('region'))?.text;
  const postalCode = !searchByPlace && !searchByZipCode
    ? formatNumber(place?.context?.find((item) => item?.id?.includes('postcode'))?.text)
    : '';

  const address = `${placeName[0]}, ${city} ${stateAbbreviation} ${postalCode}, US`;
  return {
    placeName: address,
    address: {
      addressLine1: placeName[0],
      administrativeAreaLevel1: stateAbbreviation || '',
      city,
      country: 'US',
      postalCode,
    },
  };
};

export const formatAddressFromOwnerAddress = (address: OwnerAddress): string => {
  const {
    addressLine1, city, postalCode, administrativeAreaLevel1, country,
  } = address;
  return `${addressLine1}, ${city} ${administrativeAreaLevel1} ${postalCode}, ${country}`;
};

export const formatCityPostalCode = (address?: OwnerAddress): string => {
  if (!address) return 'Select address';
  
  const { administrativeAreaLevel1: state, city, postalCode } = address;
  
  const parts: string[] = [];
  
  if (city) parts.push(city);
  if (state) parts.push(state);
  if (postalCode) parts.push(postalCode);
  
  return parts.length > 0 ? parts.join(', ') : 'Unknown location';
};
